/* 
// Custom functions & mixins
@import './core/base/functions';
@import './core/base/mixins';
@import './core/components/mixins';
@import './core/vendors/plugins/mixins';

// Custom variables
@import 'components/variables.custom';
@import './core/components/variables';
@import './core/components/variables-dark'; */

/* // Bootstrap initializaton */

@import "font-awesome/css/font-awesome.min.css";

// custom.scss
$primary: #083e60; // Main color
$secondary: #165e8d; // Secondary color
$text-main: #ffffff; // Text color for main section
$text-sec: #000000; // Text color for secondary section
.bg-main {
  background-color: $primary;
  color: $text-main;
}

.bg-sec {
  background-color: $secondary;
  color: $text-main;
}

/* @import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';  */

/* // 3rd-Party plugins variables
@import './core/vendors/plugins/variables';
@import './core/vendors/plugins/variables-dark'; */

/* Custom layout variables */
/* @import url('./layout/init.css'); */
/* @import './core/layout/base/variables';
@import 'layout/variables.custom'; */
