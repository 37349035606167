
:root {
  --main-color: #1f2022;
  --main-bg: #fff;
  --boxed-width: 1140px;
  --navbar-color: #000;
  --navbar-bg: #fff;
}

@-webkit-keyframes bounce {
  to {
    transform: translateY(60px);
  }

  0% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  to {
    transform: translateY(60px);
  }

  0% {
    transform: translateY(0);
  }
}

.app-loader {
  align-items: center;
  background: #fff;
  color: #336cfb;
  display: flex;
  font-size: 30px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.app-loader.main-loader {
  z-index: 999999;
}

.app-loader.main-loader .loader-box {
  align-items: flex-end;
  display: flex !important;
}

.app-loader.main-loader .loader-box .bounceball {
  display: inline-block;
  height: 95px;
  position: relative;
  -webkit-animation: bounce 0.5s ease-in infinite alternate;
  animation: bounce 0.5s ease-in infinite alternate;
  width: 30px;
}

.app-loader.main-loader .loader-box .bounceball:before {
  background: #336cfb;
  border-radius: 50%;
  content: "";
  display: block;
  height: 30px;
  position: absolute;
  top: 0;
  transform-origin: 50%;
  width: 30px;
}

.app-loader.main-loader .loader-box .text {
  display: flex;
  color: #1f2022;
  margin-left: 15px;
}

.app-loader.main-loader .loader-box .text span {
  color: #336cfb;
  font-size: 15px;
}

.loaded .app-loader {
  opacity: 0;
  visibility: hidden;
}

html {
  font-size: 14px;
}

body,
html {
  line-height: 1.43;
}

body {
  background: #fff;
  background: var(--main-bg);
  color: #1f2022;
  color: var(--main-color);
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Lato, sans-serif;
  font-weight: 700;
  line-height: 1.43;
  letter-spacing: 0.01em;
  margin-top: 1.42857rem;
  margin-bottom: 1.42857rem;
}

.h1,
h1 {
  font-size: 3.2rem;
  line-height: 1.12;
  margin-bottom: 2.14286rem;
}

.h2,
h2 {
  font-size: 2.85714286rem;
  line-height: 1;
}

.h3,
h3 {
  font-size: 2rem;
  line-height: 1.42857143;
}

.h4,
h4 {
  font-size: 1.71428572rem;
  line-height: 1.25;
}

.h5,
h5 {
  font-size: 1.42857143rem;
  line-height: 1.5;
}

.h6,
h6 {
  font-size: 1rem;
}

.blockquote,
blockquote,
figure,
ol,
p,
pre,
table,
ul {
  margin-top: 0;
  margin-bottom: 1.42857rem;
}

.blockquote:last-child,
blockquote:last-child,
figure:last-child,
ol:last-child,
p:last-child,
pre:last-child,
table:last-child,
ul:last-child {
  margin-bottom: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

.hr,
hr {
  margin: 1.42857rem 0;
  opacity: 0.1;
}

sub,
sup {
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

a {
  color: #336cfb;
  transition: color 0.2s ease-in-out;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

a:focus,
a:hover {
  color: #2759fa;
  outline: none;
}

img {
  height: auto;
  max-width: 100%;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

textarea {
  box-shadow: none;
}

.mark,
mark {
  padding: 0 0.2em;
  background-color: #336cfb;
  color: #fff;
}

::selection {
  background-color: #336cfb;
  color: #fff;
}

::-moz-selection {
  background-color: #336cfb;
  color: #fff;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  background: hsla(0, 0%, 100%, 0.2);
  position: absolute;
  width: 2px;
}

::-webkit-scrollbar:hover {
  display: block;
}

::-webkit-scrollbar-thumb {
  background-color: #bcbcbd;
}

::-webkit-scrollbar:horizontal {
  height: 2px;
}

body,
html {
  height: 100%;
}

body {
  /* overflow: hidden; */
  position: relative;
  width: 100%;
}





.page-box .app-container .main-content {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 0.5rem;
  position: relative;
  transition: padding 0.2s ease-in-out 0s;
  -webkit-overflow-scrolling: touch;
  background-color: #f3f5f9;
}





.page-box .app-container .main-content .main-content-wrap {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: opacity 0.2s ease-in-out 0s;
}

.page-box .app-container .main-content .main-content-wrap.full-filled {
  height: 100%;
  max-width: 100% !important;
  min-height: 100%;
  padding: 0;
}

.page-box .app-container .main-content .main-content-wrap.full-filled .page-content {
  height: 100%;
}

.page-box .app-container .main-content .main-content-wrap .page-header {
  display: flex;
  justify-content: space-between;
}

.page-box .app-container .main-content .main-content-wrap .page-header .page-title {
  margin: 0 0 2.14286rem;
}

@media (max-width: 767px) {
  .page-box .app-container .main-content .main-content-wrap .page-header .page-title {
    font-size: 2rem;
  }
}

.page-box .app-container .main-content .main-content-wrap .page-header .page-breadcrumbs {
  color: rgba(31, 32, 34, 0.5);
  font-size: 0.8rem;
  line-height: 2;
  margin: 0 0 1.42857rem;
}

.page-box .app-container .main-content.loaded .main-content-wrap {
  opacity: 1;
  transition: opacity 0s ease-in-out 0s;
}

.page-box .app-container .content-overlay {
  background: rgba(31, 32, 34, 0.1);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0.2s;
  visibility: hidden;
  width: 100%;
}

.page-box .app-container .content-overlay.show {
  opacity: 1;
  transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0s;
  visibility: visible;
}

@media (min-width: 992px) {
  .page-box .app-container .content-overlay.show {
    z-index: 9998;
  }
}

.vertical-layout .page-box .app-container {
  padding-left: 15rem;
}

@media (max-width: 991px) {
  .vertical-layout .page-box .app-container {
    padding-left: 0;
  }
}

.vertical-layout .page-box .app-container .app-navbar.horizontal .navbar-skeleton .sk-logo {
  display: none !important;
}

.vertical-layout .page-box .app-container .app-footer {
  left: 17.14286rem;
}

@media (max-width: 991px) {
  .vertical-layout .page-box .app-container .app-footer {
    left: 210;
  }
}

.vertical-layout.scrolled .app-navbar.horizontal {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

@media (max-width: 991px) {
  .horizontal-layout .app-navbar.horizontal:not(.horizontal-vertical) .app-logo {
    display: none;
  }
}

@media (min-width: 992px) {
  .horizontal-layout .app-navbar.horizontal .app-logo .logo-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .horizontal-layout .app-navbar.horizontal-vertical .app-logo {
    display: none;
  }
}

/* .boxed .page-box .app-container .main-content .main-content-wrap {
    max-width: 1140px;
    max-width: var(--boxed-width);
} */
.public-layout .page-box .app-container {
  background-size: cover;
  display: block;
  height: 100%;
  /* overflow: auto; */
  width: 100%;
}

.public-layout .page-box .app-container .content-box {
  background: #083e60;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  /* max-width: 700px; */
}

.public-layout .page-box .app-container .content-box .content-header {
  padding-top: 1.42857rem;
}

.public-layout .page-box .app-container .content-box .content-body {
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding: 0.71429rem 2.85714rem;
  width: 100%;
  height: 730px;
}

@media (max-width: 767px) {
  .public-layout .page-box .app-container .content-box .content-body {
    padding-left: 1.42857rem;
    padding-right: 1.42857rem;
  }
}

body.loaded .page-box .app-container .main-content .main-content-wrap {
  opacity: 1;
  transition: opacity 0s ease-in-out 0s;
}

.assistant-menu {
  display: block;
  padding: 0 2.85714rem 1.42857rem;
}

.assistant-menu .link {
  color: inherit;
  display: block;
  font-weight: 700;
  opacity: 0.3;
  padding: 0.35714rem 0;
  transition: opacity 0.2s ease-in-out;
}

.assistant-menu .link .link-icon {
  font-size: 1.3em;
  margin-right: 0.71429rem;
  padding: 0;
}

.assistant-menu .link:hover {
  opacity: 1;
}

@-webkit-keyframes loaderAnimation {
  0% {
    background-position: -468px 0;
  }

  to {
    background-position: 468px 0;
  }
}

#root {
  height: 100%;
}

.app-footer {
  background: #fbfbfb;
  border-top: 1px solid #e9e9e9;
  bottom: 0;
  display: block;
  height: 4.28571rem;
  left: 0;
  position: absolute;
  right: 0;
}

.app-footer .footer-wrap {
  background: inherit;
  height: 100%;
  margin: 0 auto;
  padding: 0.71429rem 1.42857rem;
  position: relative;
}

.app-footer .footer-wrap .page-breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.app-footer .footer-wrap .page-breadcrumbs .item {
  display: block;
  vertical-align: top;
  white-space: nowrap;
}

.app-footer .footer-wrap .page-breadcrumbs .item .link {
  color: currentColor;
  opacity: 0.5;
  text-decoration: none;
  transition: opacity 0.2s ease-in-out;
}

.app-footer .footer-wrap .page-breadcrumbs .item .link:hover {
  opacity: 1;
}

.app-footer .footer-wrap .page-breadcrumbs .item .separator {
  margin: 0 0.35714rem -2px;
}

.app-footer .footer-wrap .page-breadcrumbs .item:last-child .link {
  opacity: 1;
  pointer-events: none;
}

.app-footer .footer-wrap .page-breadcrumbs .item:last-child .separator {
  display: none;
}

.app-footer .footer-wrap .settings-btn .icon {
  font-size: 26px;
}

.app-footer .footer-wrap .footer-skeleton {
  background: inherit;
  height: 100%;
  left: 0;
  padding: 0.71429rem 1.42857rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0s;
  z-index: 999;
}

body.loaded .app-footer .footer-wrap .footer-skeleton {
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0.2s, visibility 0s ease-in-out 0.4s;
  visibility: hidden;
}

.app-footer .footer-wrap .footer-skeleton .row {
  height: inherit;
}

.app-footer .footer-wrap .footer-skeleton .bg {
  background: #ebebeb;
}

.app-footer .footer-wrap .footer-skeleton .bg-1 {
  background: #f5f5f5;
}

.app-footer .footer-wrap .footer-skeleton .animated-bg {
  overflow: hidden;
  position: relative;
}

.app-footer .footer-wrap .footer-skeleton .animated-bg:before {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: loaderAnimation;
  animation-name: loaderAnimation;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: transparent;
  background: linear-gradient(90deg,
      transparent 10%,
      hsla(0, 0%, 100%, 0.5) 18%,
      transparent 33%);
  background-size: 800px 100%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.app-footer .footer-wrap .footer-skeleton .page-breadcrumbs {
  margin: -0.35714rem;
}

.app-footer .footer-wrap .footer-skeleton .page-breadcrumbs .item {
  border-radius: 3px;
  display: block;
  height: 0.71429rem;
  margin: 0 0.35714rem;
  width: 80px;
}

.app-footer .footer-wrap .footer-skeleton .info {
  align-items: center;
  display: flex;
  margin: -0.35714rem;
}

.app-footer .footer-wrap .footer-skeleton .info .version {
  border-radius: 3px;
  display: block;
  height: 0.71429rem;
  margin: 0 0.35714rem;
  width: 80px;
}

.app-footer .footer-wrap .footer-skeleton .info .settings {
  background: #336cfb;
  border-radius: 500px;
  display: block;
  height: 1.42857rem;
  margin: 0 0.35714rem;
  width: 1.42857rem;
}

.boxed .app-footer .footer-wrap {
  max-width: 1140px;
  max-width: var(--boxed-width);
}

.app-logo {
  display: block;
  height: 4.28571rem;
  min-height: 4.28571rem;
  position: relative;
}

.app-logo .logo-wrap {
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  padding: 0.71429rem 2.85714rem;
  text-decoration: none;
}

.app-logo .logo-wrap .logo-img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: top;
  width: auto;
}

.app-logo .logo-wrap .logo-text {
  display: block;
  font-size: 20px;
  line-height: 1;
  margin-left: 1.42857rem;
}

.main-menu {
  display: block;
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.main-menu,
.main-menu .main-menu-wrap {
  width: inherit;
}

.main-menu .main-menu-wrap .menu-ul {
  list-style-type: none;
  margin: 0;
  padding: 2.14286rem 1.42857rem 0;
}

.main-menu .main-menu-wrap .menu-ul .menu-item {
  font-weight: 700;
  padding: 0 1.07143rem;
  position: relative;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .group-title {
  color: currentColor;
  display: block;
  opacity: 0.3;
  padding: 2.14286rem 0.35714rem 0.71429rem;
  text-transform: uppercase;
}

.main-menu .main-menu-wrap .menu-ul .menu-item:first-child .group-title {
  padding-top: 0;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link {
  align-items: center;
  background: transparent;
  color: currentColor;
  display: flex;
  height: 100%;
  justify-content: space-between;
  min-height: 2.14286rem;
  padding: 0.35714rem 0;
  position: relative;
  text-decoration: none;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-text {
  flex-grow: 2;
  flex-shrink: 1;
  margin: 3px 2px 2px 20px;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-badge {
  margin: 0 0.35714rem;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-icon {
  font-size: 1.3em;
  margin: 0 0.35714rem;
  opacity: 0.25;
  padding: 0;
  transition: none;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
  margin: 0 0.35714rem;
  transition: transform 0.1s ease-in-out;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .item-link:hover {
  background: transparent;
  color: #083e60;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub {
  background: transparent;
  max-height: 0;
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  visibility: hidden;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item {
  font-weight: 400;
  padding-left: 0.35714rem;
  padding-right: 0.35714rem;
}

.main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item:after {
  display: none;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active:after {
  transform: translateX(4px);
  transition: transform 0.2s ease-in-out 0.2s;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active>.item-link {
  color: #336cfb;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active>.item-link .link-caret {
  transform: rotate(90deg);
}

.main-menu .main-menu-wrap .menu-ul .menu-item.active>.sub {
  max-height: 1000px;
  visibility: visible;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub,
.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub>.item-link {
  position: relative;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub>.item-link .caret {
  display: block;
}

.main-menu .main-menu-wrap .menu-ul .menu-item.has-sub.active>.item-link .caret {
  transform: rotate(90deg);
}

@media (min-width: 992px) {
  .app-navbar.horizontal-vertical .main-menu {
    margin: 0 !important;
    overflow: visible;
  }
}

@media (min-width: 992px) {

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCustomScrollBox,
  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCustomScrollBox .mCSB_container {
    overflow: visible;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .mCSB_scrollTools {
    display: none !important;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul>.menu-item>.group-title {
    display: none;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item {
    padding: 0;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .item-link {
    padding: 0.71429rem;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .item-link .link-caret {
    transform: rotate(90deg);
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
    display: block !important;
    color: #000;
    height: auto !important;
    left: 0;
    max-height: none;
    opacity: 0;
    padding: 0.71429rem 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0.2s;
    visibility: hidden !important;
    width: 17.85714rem;
    z-index: 500;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub:before {
    bottom: 2.85714rem;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item {
    width: 100%;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item .sub .menu-item .item-link {
    height: auto;
    min-height: 0;
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item:hover>.sub {
    opacity: 1;
    transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0s;
    visibility: visible !important;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item:last-child .sub {
    left: auto;
    right: 0;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item.full-sub {
    position: static;
  }

  .app-navbar.horizontal-vertical .main-menu .main-menu-wrap .menu-ul .menu-item.full-sub .sub {
    width: 100%;
  }
}

@keyframes loaderAnimation {
  0% {
    background-position: -468px 0;
  }

  to {
    background-position: 468px 0;
  }
}

.app-navbar {
  background: var(--navbar-bg);
  color: var(--navbar-color);
  display: block;
  min-height: 4.28571rem;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    color 0.2s ease-in-out;
  --mdb-navbar-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07),
    0 2px 4px rgba(0, 0, 0, 0.05);
  --mdb-navbar-padding-top: 0.5625rem;
  --mdb-navbar-brand-img-margin-right: 0.25rem;
  box-shadow: var(--mdb-navbar-box-shadow);
  will-change: box-shadow;
}

.app-navbar .navbar-wrap {
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  min-height: inherit;
  padding: 0 0.71429rem;
  position: relative;
}

.app-navbar .navbar-wrap>* {
  margin: 0 0.71429rem;
}

.app-navbar .navbar-wrap .navbar-toggle {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  width: 35px;
}

.app-navbar .navbar-wrap .navbar-toggle span {
  background: currentColor;
  display: block;
  height: 3px;
  margin: 4px 0;
  width: 35px;
}

.app-navbar .navbar-wrap .navbar-close {
  color: inherit;
  cursor: pointer;
  font-size: 30px;
  height: auto;
  position: absolute;
  right: 0.71429rem;
  top: 1.07143rem;
  width: auto !important;
  z-index: 1;
}

.app-navbar .navbar-wrap .add-patient {
  height: 100%;
  padding: 1.42857rem 2.85714rem;
}

.app-navbar .navbar-wrap .assistant-menu {
  display: block;
  padding: 0 2.85714rem 1.42857rem;
}

.app-navbar .navbar-wrap .assistant-menu .link {
  color: inherit;
  display: block;
  font-weight: 700;
  opacity: 0.3;
  padding: 0.35714rem 0;
  transition: opacity 0.2s ease-in-out;
}

.app-navbar .navbar-wrap .assistant-menu .link .tc-icon {
  margin-right: 0.71429rem;
  padding: 0;
}

.app-navbar .navbar-wrap .assistant-menu .link:hover {
  opacity: 1;
}

.boxed .app-navbar .navbar-wrap {
  max-width: 1140px;
  max-width: var(--boxed-width);
}

@media (max-width: 991px) {
  .app-navbar.horizontal-vertical {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-17.14286rem);
    transition: transform 0.2s ease-in-out;
    width: 17.14286rem;
    will-change: transform, width;
    z-index: 9997;
  }

  .app-navbar.horizontal-vertical .navbar-wrap {
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    overflow: auto;
    padding: 0;
    width: 17.14286rem;
  }

  .app-navbar.horizontal-vertical .navbar-wrap>* {
    margin: 0;
    width: 100%;
  }

  .app-navbar.horizontal-vertical.opened {
    transform: translateX(0);
  }
}

@media (min-width: 992px) {
  .app-navbar.horizontal-vertical {
    min-height: 2.85714rem;
  }
}

.app-navbar.horizontal-vertical,
.app-navbar.vertical {
  --navbar-bg: #fbfbfb;
  --navbar-color: #000;
}

.app-navbar.vertical {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: transform 0.2s ease-in-out;
  width: calc(15rem);
  will-change: transform, width;
  -webkit-overflow-scrolling: touch;
  z-index: 9997;
}

@media (max-width: 991px) {
  .app-navbar.vertical {
    transform: translateX(-17.14286rem);
  }
}

.app-navbar.vertical .navbar-wrap {
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0;
  width: 17.14286rem;
}

.app-navbar.vertical .navbar-wrap>* {
  margin: 0;
  width: 100%;
}

.app-navbar.vertical.opened {
  transform: translateX(0);
}

.app-navbar.dark {
  --navbar-color: #fff;
  --navbar-bg: #1f2022;
}

.app-navbar .navbar-skeleton {
  align-items: center;
  background: inherit;
  display: flex;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  padding: 0 0.71429rem;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out 0s, visibility 0s ease-in-out 0s;
  z-index: 999;
}

body.loaded .app-navbar .navbar-skeleton {
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0.2s, visibility 0s ease-in-out 0.4s;
  visibility: hidden;
}

.app-navbar .navbar-skeleton .bg {
  background: #ebebeb;
}

.app-navbar .navbar-skeleton .bg-1 {
  background: #f5f5f5;
}

.app-navbar .navbar-skeleton .left-part {
  flex-grow: 1;
}

.app-navbar .navbar-skeleton .navbar-button {
  display: block;
  height: 30px;
  margin-right: 1.42857rem;
  width: 35px;
}

.app-navbar .navbar-skeleton .search {
  border-radius: 20px;
  display: block;
  height: 40px;
  max-width: 100%;
  width: 350px;
}

.app-navbar .navbar-skeleton .icon-box {
  position: relative;
}

.app-navbar .navbar-skeleton .icon-box .icon {
  border-radius: 5px;
  display: block;
  height: 20px;
  width: 20px;
}

.app-navbar .navbar-skeleton .icon-box .badge {
  background: #ed5564;
  border: 0;
  border-radius: 20px;
  display: block;
  height: 16px;
  padding: 0;
  position: absolute;
  right: -8px;
  top: -10px;
  width: 16px;
}

.app-navbar .navbar-skeleton .avatar {
  border-radius: 20px;
  display: block;
  height: 40px;
  margin: 0 18px 0 25px;
  width: 40px;
}

.app-navbar .navbar-skeleton .sk-logo {
  border-radius: 5px;
  display: block;
  height: 2.85714rem;
  margin-right: 1.42857rem;
  max-width: 169px;
  width: 100%;
}

.app-navbar .navbar-skeleton .animated-bg {
  overflow: hidden;
  position: relative;
}

.app-navbar .navbar-skeleton .animated-bg:before {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: loaderAnimation;
  animation-name: loaderAnimation;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: transparent;
  background: linear-gradient(90deg,
      transparent 10%,
      hsla(0, 0%, 100%, 0.5) 18%,
      transparent 33%);
  background-size: 800px 100%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.app-navbar .navbar-skeleton.vertical {
  flex-direction: column;
}

.app-navbar .navbar-skeleton.vertical .bottom-part,
.app-navbar .navbar-skeleton.vertical .top-part {
  padding: 0.71429rem 2.14286rem;
  width: 100%;
}

.app-navbar .navbar-skeleton.vertical .bottom-part {
  padding-bottom: 2.14286rem;
}

.app-navbar .navbar-skeleton.vertical .sk-logo {
  margin: 0;
}

.app-navbar .navbar-skeleton.vertical .sk-menu {
  margin: 3.21429rem 0 0;
}

.app-navbar .navbar-skeleton.vertical .sk-menu .sk-menu-item {
  border-radius: 3px;
  display: block;
  height: 1.07143rem;
  margin: 1.07143rem 0 0;
}

.app-navbar .navbar-skeleton.vertical .sk-menu .sk-menu-item.menu-header {
  width: 50%;
}

.app-navbar .navbar-skeleton.vertical .sk-button {
  background: #336cfb;
  border-radius: 6px;
  height: 2.85714rem;
  margin-top: 2.14286rem;
}

.app-navbar .navbar-skeleton.vertical .horizontal-menu {
  align-items: center;
  align-self: flex-start;
  display: none;
  height: 100%;
  margin: 0 0.71429rem;
}

.app-navbar .navbar-skeleton.vertical .horizontal-menu .sk-menu-item {
  border-radius: 3px;
  display: block;
  height: 0.71429rem;
  margin: 0 0.71429rem 0 0;
  width: 5.71429rem;
}

@media (min-width: 992px) {

  .app-navbar.horizontal-vertical .navbar-skeleton.vertical .bottom-part,
  .app-navbar.horizontal-vertical .navbar-skeleton.vertical .top-part {
    display: none;
  }

  .app-navbar.horizontal-vertical .navbar-skeleton.vertical .horizontal-menu {
    display: flex;
  }
}

.app-search {
  display: block;
  max-width: 25rem;
  width: 50%;
}

.app-search .typeahead__query {
  z-index: auto;
}

.app-actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important;
}

.app-actions .item {
  margin: 0 0.71429rem;
}

.app-actions .item .dropdown-toggle {
  color: inherit;
  display: block;
}

.app-actions .item .dropdown-toggle .icon {
  font-size: 26px;
  opacity: 0.5;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.app-actions .item .dropdown-toggle:after {
  display: none;
}

.app-actions .item .dropdown-toggle .badge {
  border: 2px solid var(--navbar-bg);
  justify-content: center;
  min-width: 21px;
  padding-left: 2px;
  padding-right: 2px;
  position: absolute;
  right: -5px;
  text-align: center;
  top: -5px;
}

.app-actions .item .dropdown-toggle:hover .icon {
  opacity: 1;
}

.app-actions .item .dropdown-menu {
  z-index: 9999;
}

.app-actions .item.show .dropdown-toggle .icon {
  color: #336cfb;
  opacity: 1;
}

.alert {
  border: 1px solid transparent;
  border-radius: 6px;
  position: relative;
  padding: calc(1.42858rem - 1px);
}

.alert .title {
  font-size: 1.25em;
  font-weight: 500;
  margin-bottom: 0.35714rem;
}

.alert .btn-close {
  align-self: flex-start;
  color: inherit;
  cursor: pointer;
  font-size: 1.25em;
  line-height: 1;
  margin: 0.71429rem;
  padding: 0;
  opacity: 0;
  text-shadow: none;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.alert .alert-icon {
  align-items: center;
  background: hsla(0, 0%, 100%, 0.2);
  display: flex;
  font-size: 1.43em;
  line-height: 1;
  min-height: 100%;
  padding: 0.71429rem 1.07143rem;
}

.alert.alert-secondary {
  /* background: hsla(0, 0%, 92.2%, 0.3); */
  color: inherit;
  border-color: hsla(0, 0%, 92.2%, 0.3);
}

.alert.alert-secondary.outline {
  background: transparent;
  color: hsla(0, 0%, 92.2%, 0.3);
}

.alert.alert-primary {
  background: #336cfb;
  color: #fff;
  border-color: #336cfb;
}

.alert.alert-primary.outline {
  background: transparent;
  color: #336cfb;
}

.alert.alert-success {
  background: #b7ce63;
  color: #000;
  border-color: #b7ce63;
}

.alert.alert-success.outline {
  background: transparent;
  color: #b7ce63;
}

.alert.alert-info {
  background: #64b5f6;
  color: #000;
  border-color: #64b5f6;
}

.alert.alert-info.outline {
  background: transparent;
  color: #64b5f6;
}

.alert.alert-warning {
  background: #e9e165;
  color: #000;
  border-color: #e9e165;
}

.alert.alert-warning.outline {
  background: transparent;
  color: #e9e165;
}

.alert.alert-danger {
  background: #ed5564;
  color: #fff;
  border-color: #ed5564;
}

.alert.alert-danger.outline {
  background: transparent;
  color: #ed5564;
}

.alert.alert-light {
  background: #e4e4e4;
  color: #000;
  border-color: #e4e4e4;
}

.alert.alert-light.outline {
  background: transparent;
  color: #e4e4e4;
}

.alert.alert-dark {
  background: #1f2022;
  color: #fff;
  border-color: #1f2022;
}

.alert.alert-dark.outline {
  background: transparent;
  color: #1f2022;
}

.alert.with-after-icon,
.alert.with-before-icon {
  display: flex;
  padding: 0 0.71429rem;
}

.alert.with-after-icon .alert-content,
.alert.with-before-icon .alert-content {
  flex: 1;
  padding: calc(1.42858rem - 1px) 0.71429rem;
}

.alert.with-before-icon {
  padding-left: 0;
}

.alert.with-after-icon {
  padding-right: 0;
}

.alert:hover .btn-close {
  opacity: 1;
}

.badge {
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 500px;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.43;
  padding: 0.35715rem calc(1.42858rem - 1px);
  vertical-align: top;
}

.badge.badge-xs {
  padding: 0 calc(0.35715rem - 1px);
}

.badge.badge-sm,
.badge.badge-xs {
  font-size: 0.7rem;
  line-height: 1.53061;
}

.badge.badge-sm {
  padding: calc(0.17857rem - 1px) calc(0.71429rem - 1px);
}

.badge.badge-lg {
  font-size: 1.2rem;
  line-height: 1.19048;
  padding: calc(0.71429rem - 1px) calc(2.14287rem - 1px);
}

.badge.badge-inside {
  font-size: 75%;
  padding: 0.1em 1em;
}

/* .badge.badge-primary {
    background: #336cfb;
    border-color: #336cfb;
    color: #fff;
}
.badge.badge-primary.badge-pill {
    background: transparent !important;
    color: #336cfb;
}
.badge.badge-secondary {
    background: #626364;
    border-color: #626364;
    color: #fff;
}
.badge.badge-secondary.badge-pill {
    background: transparent !important;
    color: #626364;
} */
/* .badge.badge-success {
    background: #b7ce63;
    border-color: #b7ce63;
    color: #000;
}
.badge.badge-success.badge-pill {
    background: transparent !important;
    color: #b7ce63;
} */
/* .badge.badge-info {
    background: #64b5f6;
    border-color: #64b5f6;
    color: #000;
}
.badge.badge-info.badge-pill {
    background: transparent !important;
    color: #64b5f6;
}
.badge.badge-warning {
    background: #e9e165;
    border-color: #e9e165;
    color: #000;
}
.badge.badge-warning.badge-pill {
    background: transparent !important;
    color: #e9e165;
}
.badge.badge-error {
    background: #ed5564;
    border-color: #ed5564;
    color: #fff;
}
.badge.badge-error.badge-pill {
    background: transparent !important;
    color: #ed5564;
}
.badge.badge-danger {
    background: #ed5564;
    border-color: #ed5564;
    color: #fff;
}
.badge.badge-danger.badge-pill {
    background: transparent !important;
    color: #ed5564;
} */
.badge.badge-light {
  background: #e4e4e4;
  border-color: #e4e4e4;
  color: #000;
}

.badge.badge-light.badge-pill {
  background: transparent !important;
  color: #e4e4e4;
}

.badge.badge-dark {
  background: #0a0b0c;
  border-color: #0a0b0c;
  color: #fff;
}

.badge.badge-dark.badge-pill {
  background: transparent !important;
  color: #0a0b0c;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.btn {
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1.42857;
  outline: none;
  padding: calc(0.71429rem - 0.5px) 1.42857rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  touch-action: manipulation;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  will-change: background, border, box-shadow, color, opacity;
  -webkit-appearance: none;
}

.btn:hover {
  border-color: transparent;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.btn .btn-icon {
  font-size: 1.3em;
  margin: 0;
}

.btn .btn-loader {
  align-items: center;
  background: inherit;
  border-radius: inherit;
  display: flex;
  font-size: 150%;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;
}

.btn .btn-loader:before {
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}

.btn.btn-square {
  min-width: 2.85714rem;
}

.btn.btn-square,
.btn.btn-square.btn-sm {
  padding-left: 0.35714rem;
  padding-right: 0.35714rem;
}

.btn.btn-square.btn-sm {
  min-width: 2.5rem;
}

.btn.btn-square.btn-lg {
  min-width: 3.57143rem;
  padding-left: 0.71429rem;
  padding-right: 0.71429rem;
}

.btn.btn-lg {
  font-size: 1.2rem;
  line-height: 1.19048;
  padding: calc(1.07143rem - 0.5px) 2.14286rem;
}

.btn.btn-sm {
  font-size: 0.8rem;
  line-height: 0.89286;
  padding: calc(0.71429rem - 0.5px) 0.71429rem;
}

.btn.btn-primary {
  background: #083e60;
  border-color: #083e60;
  color: #fff;
}

.btn.btn-primary:hover {
  background: #083e60;
  color: #fff;
}

.btn.btn-outline-primary {
  border-color: #083e60;
  color: #083e60;
}

.active .btn.btn-outline-primary,
.btn.btn-outline-primary:hover {
  background: #083e60;
  color: #fff;
}

.btn.btn-secondary {
  background: #626364;
  border-color: #626364;
  color: #fff;
}

.btn.btn-secondary:hover {
  background: #1f2022;
  color: #fff;
}

.btn.btn-outline-secondary {
  border-color: #626364;
  color: #626364;
}

.active .btn.btn-outline-secondary,
.btn.btn-outline-secondary:hover {
  background: #626364;
  color: #fff;
}

.btn.btn-success {
  background: #b7ce63;
  border-color: #b7ce63;
  color: #000;
}

.btn.btn-success:hover {
  background: #a5c13d;
  color: #000;
}

.btn.btn-outline-success {
  border-color: #b7ce63;
  color: #b7ce63;
}

.active .btn.btn-outline-success,
.btn.btn-outline-success:hover {
  background: #b7ce63;
  color: #000;
}

.btn.btn-info {
  background: #64b5f6;
  border-color: #64b5f6;
  color: #000;
}

.btn.btn-info:hover {
  background: #349ef3;
  color: #000;
}

.btn.btn-outline-info {
  border-color: #64b5f6;
  color: #64b5f6;
}

.active .btn.btn-outline-info,
.btn.btn-outline-info:hover {
  background: #64b5f6;
  color: #000;
}

.btn.btn-warning {
  background: #e9e165;
  border-color: #e9e165;
  color: #000;
}

.btn.btn-warning:hover {
  background: #e3d838;
  color: #000;
}

.btn.btn-outline-warning {
  border-color: #e9e165;
  color: #e9e165;
}

.active .btn.btn-outline-warning,
.btn.btn-outline-warning:hover {
  background: #e9e165;
  color: #000;
}

.btn.btn-error {
  background: #ed5564;
  border-color: #ed5564;
  color: #fff;
}

.btn.btn-error:hover {
  background: #e8273a;
  color: #e6e6e6;
}

.btn.btn-outline-error {
  border-color: #ed5564;
  color: #ed5564;
}

.active .btn.btn-outline-error,
.btn.btn-danger,
.btn.btn-outline-error:hover {
  background: #ed5564;
  color: #fff;
}

.btn.btn-danger {
  border-color: #ed5564;
}

.btn.btn-danger:hover {
  background: #e8273a;
  color: #e6e6e6;
}

.btn.btn-outline-danger {
  border-color: #ed5564;
  color: #ed5564;
}

.active .btn.btn-outline-danger,
.btn.btn-outline-danger:hover {
  background: #ed5564;
  color: #fff;
}

.btn.btn-light {
  background: #e4e4e4;
  border-color: #e4e4e4;
  color: #000;
}

.btn.btn-light:hover {
  background: #cbcbcb;
  color: #000;
}

.btn.btn-outline-light {
  border-color: #e4e4e4;
  color: #e4e4e4;
}

.active .btn.btn-outline-light,
.btn.btn-outline-light:hover {
  background: #e4e4e4;
  color: #000;
}

.btn.btn-dark {
  background: #0a0b0c;
  border-color: #0a0b0c;
  color: #fff;
}

.btn.btn-dark:hover {
  background: #000;
  color: #e6e6e6;
}

.btn.btn-outline-dark {
  border-color: #0a0b0c;
  color: #0a0b0c;
}

.active .btn.btn-outline-dark,
.btn.btn-outline-dark:hover {
  background: #0a0b0c;
  color: #fff;
}

.btn.btn-outline {
  background: transparent;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.6;
}

.btn.btn-load,
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.btn.btn-load .btn-icon,
.btn.btn-load .btn-text {
  visibility: hidden;
}

.btn.btn-load .btn-loader {
  visibility: visible;
}

.btn.btn-icon-animation .btn-icon {
  align-items: center;
  background: inherit;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 0.2s ease-in-out;
  transform: scale(0);
  will-change: transform;
}

.active .btn.btn-icon-animation .btn-icon,
.btn.btn-icon-animation:hover .btn-icon {
  transform: scale(1);
}

.card {
  background: transparent 50% 50% no-repeat;
  background-size: cover;
  border: 0;
  border-radius: 6px;
  display: block;
  margin-bottom: 1rem;
  position: relative;
}

.card .card-img {
  width: 100%;
}

.card .card-img,
.card .card-img-top {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.card .card-img-overlay {
  overflow: auto;
}

.card .card-header {
  background: none;
  border: 0;
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.card .card-body {
  display: block;
  padding: 1.42857rem 0 0;
  position: relative;
  width: 100%;
}

.card .card-body:first-child {
  padding-top: 0;
}

.card .overlay-img {
  border-radius: inherit;
}

.card img {
  max-width: 100%;
  height: auto;
}

.card.bg-danger,
.card.bg-dark,
.card.bg-info,
.card.bg-light,
.card.bg-primary,
.card.bg-secondary,
.card.bg-success,
.card.bg-warning,
.card.border-danger,
.card.border-dark,
.card.border-info,
.card.border-light,
.card.border-primary,
.card.border-secondary,
.card.border-success,
.card.border-warning {
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
}

.card.bg-danger .card-header,
.card.bg-dark .card-header,
.card.bg-info .card-header,
.card.bg-light .card-header,
.card.bg-primary .card-header,
.card.bg-secondary .card-header,
.card.bg-success .card-header,
.card.bg-warning .card-header,
.card.border-danger .card-header,
.card.border-dark .card-header,
.card.border-info .card-header,
.card.border-light .card-header,
.card.border-primary .card-header,
.card.border-secondary .card-header,
.card.border-success .card-header,
.card.border-warning .card-header {
  padding: 0.71429rem 1.42857rem 0;
}

.card.bg-danger .card-body,
.card.bg-dark .card-body,
.card.bg-info .card-body,
.card.bg-light .card-body,
.card.bg-primary .card-body,
.card.bg-secondary .card-body,
.card.bg-success .card-body,
.card.bg-warning .card-body,
.card.border-danger .card-body,
.card.border-dark .card-body,
.card.border-info .card-body,
.card.border-light .card-body,
.card.border-primary .card-body,
.card.border-secondary .card-body,
.card.border-success .card-body,
.card.border-warning .card-body {
  padding: 1.42857rem;
}

.card.border-danger,
.card.border-dark,
.card.border-info,
.card.border-light,
.card.border-primary,
.card.border-secondary,
.card.border-success,
.card.border-warning {
  background: none;
  border-style: solid;
  border-width: 1px;
}

.form-check {
  min-height: 1.42857rem;
  margin-bottom: 0.71429rem;
}

.custom-control {
  padding-left: 2.14286rem;
  position: relative;
  transition: color 0.2s ease-in-out;
}

.form-check-label {
  font-weight: 500;
  margin-bottom: 0;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox .custom-control-label {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-checkbox .custom-control-label:after,
.custom-checkbox .custom-control-label:before {
  align-items: center;
  border-radius: 3px;
  box-shadow: none !important;
  display: flex;
  height: 1.42857rem;
  justify-content: center;
  left: 0;
  margin: 0;
  outline: none !important;
  padding: 0;
  position: absolute;
  top: 0;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  width: 1.42857rem;
}

.custom-checkbox .custom-control-label:before {
  background: #ebebeb;
  border: 1px solid #ebebeb;
  content: "";
  pointer-events: auto;
}

.custom-checkbox .custom-control-label:after {
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  color: #fff;
  content: "\eed6";
  direction: ltr;
  font-family: IcoFont !important;
  font-size: 1.6rem;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 1;
  pointer-events: none;
  speak: none;
  text-transform: none;
  transform: scale(0);
  white-space: nowrap;
  word-wrap: normal;
}

.custom-checkbox .custom-control-label:hover {
  color: inherit;
}

.custom-checkbox .custom-control-label:hover:before {
  background: #ebebeb;
  border-color: #ebebeb;
}

.custom-checkbox .custom-control-label:hover:after {
  color: #336cfb;
  transform: scale(1);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:before {
  background: #336cfb;
  border-color: #336cfb;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label:after {
  background-image: none !important;
  color: #fff;
  transform: scale(1);
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label {
  color: #bdbdbd;
  cursor: not-allowed;
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label:before {
  background: hsla(0, 0%, 80%, 0.1);
  border-color: hsla(0, 0%, 80%, 0.5);
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label:after {
  color: inherit;
  transform: scale(0);
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:before {
  background: hsla(0, 0%, 80%, 0.1);
  border-color: hsla(0, 0%, 80%, 0.5);
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label:after {
  transform: scale(1);
}

.contact {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
  display: block;
  margin: 0 auto 1.42857rem;
  max-width: 400px;
  text-align: center;
  transition: box-shadow 0.2s ease-in-out;
}

.contact:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.contact .img-box {
  background: rgba(0, 0, 0, 0.05);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  overflow: hidden;
}

.contact .info-box {
  padding: 1.42857rem 1.42857rem 2.14286rem;
}

.contact .info-box .name {
  margin: 0 0 0.71429rem;
}

.contact .info-box .role {
  opacity: 0.6;
}

.contact .info-box .social {
  display: flex;
  justify-content: center;
  margin: 0 -0.35714rem 1.42857rem;
}

.contact .info-box .social .link {
  color: inherit;
  display: block;
  font-size: 1.43rem;
  margin: 0 0.35714rem;
}

.contact .info-box .social .link:hover {
  color: #336cfb;
}

.contact .info-box .address {
  opacity: 0.6;
}

.dropdown-toggle-split {
  padding-left: 0.71429rem;
  padding-right: 0.71429rem;
}

.dropdown-menu {
  background: #fff;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 0.71429rem 0;
}

.dropdown-menu .menu-list {
  font-size: 13px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
}

.dropdown-menu .menu-list li a {
  color: rgba(51, 51, 51, 0.65);
  display: block;
  padding: 5px 15px;
  text-decoration: none;
}

.dropdown-menu .menu-list li a:hover {
  color: #333;
}

.dropdown-menu .menu-footer,
.dropdown-menu .menu-header {
  margin: 0;
  padding: 0.71429rem 1.42857rem;
}

.dropdown-menu .menu-header {
  align-items: center;
  border-radius: 6px 6px 0 0;
  color: #1f2022;
  display: flex;
  justify-content: space-between;
}

.dropdown-menu .menu-footer {
  border-radius: 0 0 6px 6px;
}

.dropdown-menu .list {
  list-style-type: none;
  margin: 0;
  max-height: 280px;
  overflow: auto;
  padding: 0;
}

.dropdown-menu .list>li>a {
  color: #1f2022;
  color: var(--main-color);
  display: flex;
  padding: 0.71429rem 1.42857rem;
  text-decoration: none;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.dropdown-menu .list>li>a .avatar,
.dropdown-menu .list>li>a .icon,
.dropdown-menu .list>li>a .tc-icon {
  align-self: flex-start;
  margin-right: 0.71429rem;
}

.dropdown-menu .list>li>a .icon {
  background: rgba(31, 32, 34, 0.1);
  border-radius: 50%;
  font-size: 1rem;
  height: auto;
  padding: 0.71429rem;
  transition: background 0.2s ease-in-out;
  width: auto;
}

.dropdown-menu .list>li>a .avatar {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.dropdown-menu .list>li>a .content {
  font-size: 0.85rem;
}

.dropdown-menu .list>li>a .content .desc {
  display: block;
}

.dropdown-menu .list>li>a .content .date,
.dropdown-menu .list>li>a .content .size {
  display: block;
  font-size: 0.75rem;
  margin-top: 4px;
  opacity: 0.6;
}

.dropdown-menu .list>li>a .content .meta {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  line-height: 1.3;
  margin-bottom: 4px;
}

.dropdown-menu .list>li>a .content .meta .date {
  margin: 0 0 0 11px;
}

.dropdown-menu .list>li>a:hover {
  color: #083e60;
}

.dropdown-menu .list>li>a:hover .icon {
  background: #083e60;
  color: #fff;
}

.dropdown-menu .list>li:first-child>a {
  border-top: 0;
}

.dropdown-menu .list>li .empty-item {
  display: block;
  padding: 0.71429rem 1.42857rem;
}

.dropdown-menu .author-info {
  padding: 1.42857rem 0.71429rem 0;
  text-align: center;
}

.dropdown-menu .author-info .author-name {
  font-size: 1rem;
}

.dropdown-menu .dropdown-overlay {
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.1s ease-in-out 0.2s;
  z-index: 9997;
}

.dropdown-menu .dropdown-overlay .overlay-bg {
  background: rgba(31, 32, 34, 0.1);
  display: block;
  height: 100%;
  width: 100%;
}

.dropdown-menu.dropdown-menu-w-180 {
  width: 180px;
}

.dropdown-menu.dropdown-menu-w-280 {
  width: 280px;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.PhoneInputInput {
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  color: #1f2022;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: 1.43;
  outline: none;
  padding: calc(0.71429rem - 1px) 1.42857rem;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  will-change: background, border, box-shadow, color;
}

.PhoneInputInput::-webkit-input-placeholder {
  color: #a8a8a8;
}

.PhoneInputInput :-moz-placeholder,
.PhoneInputInput ::-moz-placeholder {
  color: #a8a8a8;
}

.PhoneInputInput :-ms-input-placeholder {
  color: #a8a8a8;
}

.PhoneInputInput:hover {
  background: #ebebeb;
  border-color: #ebebeb;
}

.PhoneInputInput:focus,
.PhoneInputInput:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  color: #1f2022;
}

.PhoneInputInput:focus {
  background: #fff;
  border-color: #336cfb;
}

.form-control {
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  color: #1f2022;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: 1.43;
  outline: none;
  padding: calc(0.71429rem - 1px) 1.42857rem;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  will-change: background, border, box-shadow, color;
  width: 100%;
}

.form-control ::-webkit-input-placeholder {
  color: #a8a8a8;
}

.form-control :-moz-placeholder,
.form-control ::-moz-placeholder {
  color: #a8a8a8;
}

.form-control :-ms-input-placeholder {
  color: #a8a8a8;
}

.form-control:hover {
  background: #ebebeb;
  border-color: #ebebeb;
}

.form-control:focus,
.form-control:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  color: #1f2022;
}

.form-control:focus {
  background: #fff;
  border-color: #336cfb;
}

.form-control .tc-input-wrap .input-prefix,
.form-control .tc-input-wrap .input-suffix {
  color: #a8a8a8;
  flex: none;
  padding: 0 0.2em;
}

.form-control .tc-input-wrap .input-icon {
  font-size: 1.3em;
}

.form-control .tc-input-wrap .char-limiting {
  align-items: center;
  color: #a8a8a8;
  display: flex;
  font-size: 0.9em;
  justify-content: center;
  padding: 0 0.2em;
  pointer-events: none;
}

.form-control.ng-invalid.ng-touched .tc-input-wrap,
.has-error .form-control .tc-input-wrap {
  background-color: rgba(237, 85, 100, 0.1);
  border: 1px solid #ed5564;
}

.form-control.form-control-sm {
  border-radius: 16px;
  font-size: 0.9rem;
  height: 2.14286rem;
  line-height: 1.5873;
  padding-top: 0.35714rem;
  padding-bottom: 0.35714rem;
}

.form-control.form-control-sm .input-icon {
  font-size: 1.1em;
}

.form-control.form-control-lg {
  border-radius: 30px;
  font-size: 1.3rem;
  height: 3.57143rem;
  line-height: 1.0989;
  padding-top: 1.07143rem;
  padding-bottom: 1.07143rem;
}

.form-control.disabled,
.form-control:disabled,
.form-control[readonly] {
  background-color: hsla(0, 0%, 80%, 0.1);
  border-color: hsla(0, 0%, 80%, 0.5);
  box-shadow: none !important;
  color: #bdbdbd;
  cursor: not-allowed;
}

.form-control-plaintext {
  color: #1f2022;
  font-size: 1rem;
  height: 2.85714rem;
  line-height: 1.43;
  padding-top: calc(0.71429rem - 1px);
  padding-bottom: calc(0.71429rem - 1px);
}

.form-control-plaintext.form-control-sm {
  font-size: 0.9rem;
  height: 2.14286rem;
  line-height: 1.5873;
  padding-top: 0.35714rem;
  padding-bottom: 0.35714rem;
}

.form-control-plaintext.form-control-lg {
  font-size: 1.3rem;
  height: 3.57143rem;
  line-height: 1.0989;
  padding-top: 1.07143rem;
  padding-bottom: 1.07143rem;
}

.form-group {
  margin-bottom: 1.42857rem;
  position: relative;
}

.form-text {
  display: block;
  font-size: 1rem;
  margin: 0.71429rem 0 0;
}

label {
  font-weight: 600;
  margin: 0 0 0.71429rem;
}

.input-group-text {
  background: #ebebeb;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  padding-left: 1.42857rem;
  padding-right: 1.42857rem;
}

.input-group .btn {
  border-radius: 20px;
}

.input-group .typeahead__cancel-button {
  z-index: 1;
}

.input-group .typeahead__cancel-button+.form-control {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.input-group .input-group-prepend~.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.typeahead__container .input-group-append,
.typeahead__container .input-group-prepend {
  border-radius: 20px;
}

.typeahead__container .input-group-append .input-group-text,
.typeahead__container .input-group-prepend .input-group-text {
  border-radius: inherit;
  height: 100%;
}

.with-prefix-icon,
.with-suffix-icon {
  position: relative;
}

.with-prefix-icon .prefix-icon,
.with-prefix-icon .suffix-icon,
.with-suffix-icon .prefix-icon,
.with-suffix-icon .suffix-icon {
  align-items: center;
  color: #a8a8a8;
  display: flex;
  height: 100%;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 2.85714rem;
}

.with-prefix-icon .prefix-icon,
.with-suffix-icon .prefix-icon {
  left: 0;
}

.with-prefix-icon .suffix-icon,
.with-suffix-icon .suffix-icon {
  right: 0;
}

.with-prefix-icon .form-control {
  padding-left: 2.85714rem;
}

.with-suffix-icon .form-control {
  padding-right: 2.85714rem;
}

textarea.form-control {
  min-height: 2.85714rem;
  resize: none;
}

textarea.form-control.resize {
  resize: vertical;
}

.form-control.is-invalid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid {
  background-position: top 50% right 0.71429rem;
}

.was-validated textarea.form-control:invalid,
.was-validated textarea.form-control:valid,
textarea.form-control.is-invalid,
textarea.form-control.is-valid {
  background-position: top 0.71429rem right 0.71429rem;
}

.modal {
  z-index: 9999;
}

.modal-content {
  background: #fff;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
}

.modal-content .btn-close {
  position: absolute;
  right: 3px;
  top: 3px;
}

.modal-content .modal-header {
  align-items: center;
  border: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  margin: 0;
  padding: 1.22857rem 1.07143rem;
}

.modal-content .modal-header>* {
  margin: 0 0.35714rem;
}

.modal-content .modal-header .modal-title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
}

.modal-content .modal-body {
  padding: 1.42857rem;
}

.modal-content .modal-footer {
  background: rgba(0, 0, 0, 0.05);
  border: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 1.42857rem;
}

.modal-content .modal-footer .actions {
  display: flex;
  flex-wrap: wrap;
  margin: -0.35714rem;
}

.modal-content .modal-footer .actions>* {
  margin: 0.35714rem;
}

.modal-dialog-bottom {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 3.5rem);
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.1);
  z-index: 9998;
}

.custom-radio .custom-control-label {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-radio .custom-control-label:after,
.custom-radio .custom-control-label:before {
  align-items: center;
  border-radius: 50%;
  box-shadow: none !important;
  display: flex;
  content: "";
  height: 1.42857rem;
  justify-content: center;
  left: 0;
  margin: 0;
  outline: none !important;
  padding: 0;
  position: absolute;
  top: 0;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    transform 0.2s ease-in-out;
  width: 1.42857rem;
}

.custom-radio .custom-control-label:before {
  background: #ebebeb;
  border: 1px solid #ebebeb;
  pointer-events: auto;
}

.custom-radio .custom-control-label:after {
  background: #336cfb;
  border-radius: 50%;
  height: calc(1.42857rem - 8px);
  margin: 4px 0 0 4px;
  transform: scale(0);
  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
  width: calc(1.42857rem - 8px);
}

.custom-radio .custom-control-label:hover {
  color: inherit;
}

.custom-radio .custom-control-label:hover:before {
  background: #ebebeb;
  border-color: #ebebeb;
}

.custom-radio .custom-control-label:hover:after {
  background: #336cfb;
  transform: scale(1);
}

.custom-radio .custom-control-input:checked~.custom-control-label:before {
  background: #336cfb;
  border-color: #336cfb;
}

.custom-radio .custom-control-input:checked~.custom-control-label:after {
  background: #fff;
  transform: scale(1);
}

.custom-radio .custom-control-input:disabled~.custom-control-label {
  color: #bdbdbd;
  cursor: not-allowed;
}

.custom-radio .custom-control-input:disabled~.custom-control-label:before {
  background: hsla(0, 0%, 80%, 0.1);
  border-color: hsla(0, 0%, 80%, 0.5);
}

.custom-radio .custom-control-input:disabled~.custom-control-label:after {
  background: #bdbdbd;
  transform: scale(0);
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:before {
  background: hsla(0, 0%, 80%, 0.1);
  border-color: hsla(0, 0%, 80%, 0.5);
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label:after {
  transform: scale(1);
}

.br-wrapper .rating.rating-sm+.br-widget {
  font-size: 1rem;
}

.br-wrapper .rating.rating-lg+.br-widget {
  font-size: 2rem;
}

.br-wrapper .br-widget {
  display: flex;
  font-size: 1.43rem;
  margin: 0 -0.17857rem;
  white-space: nowrap;
}

.br-wrapper .br-widget a {
  color: #c1c1c1;
  cursor: pointer;
  display: block;
  font-size: inherit;
  margin: 0 0.17857rem;
  transition: border 0.2s ease-in-out, color 0.2s ease-in-out;
  font-family: IcoFont !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.br-wrapper .br-widget a:after {
  content: "\f000";
  line-height: 1;
}

.br-wrapper .br-widget a.number {
  border: 2px solid #c1c1c1;
  min-width: 2.14286rem;
  padding: calc(0.35715rem - 2px);
  text-align: center;
}

.br-wrapper .br-widget a.br-active {
  color: #ffeb3d;
}

.br-wrapper .br-widget a.br-active.number {
  border-color: #ffeb3d;
}

.br-wrapper .br-widget a.br-selected {
  color: #faa110;
}

.br-wrapper .br-widget a.br-selected.number {
  border-color: #faa110;
}

.br-wrapper .br-widget.rating-numbered {
  font-size: 1rem;
}

.br-wrapper .br-widget.br-readonly a {
  cursor: default;
}

.bootstrap-select,
.bootstrap-select .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select,
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select.show .form-control {
  background: rgba(51, 108, 251, 0.01);
  border-color: #336cfb;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  color: #1f2022;
}

.bootstrap-select.rounded-0>.dropdown-toggle,
.bootstrap-select.rounded-20>.dropdown-toggle,
.bootstrap-select.rounded-500>.dropdown-toggle,
.bootstrap-select.rounded-pill>.dropdown-toggle,
.bootstrap-select.rounded>.dropdown-toggle {
  border-radius: inherit;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  font-size: 1.5em;
  top: 2px;
}

.form-switch {
  min-height: 1.42857rem;
  padding-left: 3.57143rem;
}

.form-switch .form-check-label {
  color: inherit;
  cursor: pointer;
  font-weight: 500;
}

.form-switch .form-check-input {
  background-color: #ebebeb;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%231f2022"/></svg>');
  border: 1px solid #ebebeb;
  border-radius: 500px;
  cursor: pointer;
  display: block;
  height: 1.42857rem;
  margin: 0 0 0 -3.57143rem;
  transition: background-position 0.2s ease-in-out,
    background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  width: 2.85714rem;
}

.form-switch .form-check-input:hover:not(:checked) {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"><circle r="3" fill="%23336cfb"/></svg>');
}

.form-switch .form-check-input:checked {
  background-color: #083e60;
  border-color: #083e60;
}


.v-timeline {
  display: block;
  position: relative;
}

.v-timeline .line {
  background: #e4e4e4;
  bottom: 1.42857rem;
  left: 1.42857rem;
  margin: 0 -1px;
  position: absolute;
  top: 1.42857rem;
  width: 2px;
}

.v-timeline .timeline-box .box-label {
  display: flex;
  padding: 0.71429rem 0;
}

.v-timeline .timeline-box .box-label .badge {
  position: relative;
}

.v-timeline .timeline-box .box-items {
  display: flex;
  flex-direction: column;
}

.v-timeline .timeline-box .box-items .item {
  display: flex;
  margin: 1.42857rem -0.71429rem;
  position: relative;
}

.v-timeline .timeline-box .box-items .item .icon-block {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 0.71429rem;
}

.v-timeline .timeline-box .box-items .item .icon-block .item-icon {
  align-items: center;
  background: #336cfb;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 1.7rem;
  height: 2.85714rem;
  justify-content: center;
  min-height: 2.85714rem;
  width: 2.85714rem;
}

.v-timeline .timeline-box .box-items .item .content-block {
  flex-grow: 2;
  margin: 0 0.71429rem;
}

.v-timeline .timeline-box .box-items .item .content-block .item-header,
.v-timeline .timeline-box .box-items .item .content-block .item-header .item-title {
  margin: 0;
}

.v-timeline .timeline-box .box-items .item .content-block .item-header .item-date {
  color: #bcbcbd;
  margin: 0;
  white-space: nowrap;
}

.v-timeline .timeline-box .box-items .item .content-block .item-desc {
  margin-top: 0.71429rem;
}

.v-timeline.dots .line {
  left: 0.35714rem;
}

.v-timeline.dots .timeline-box .box-items .item .icon-block .item-icon {
  height: 0.71429rem;
  min-height: 0.71429rem;
  width: 0.71429rem;
}

.v-timeline.dots .timeline-box .box-items .item .icon-block .item-icon:before {
  display: none;
}

.v-timeline.align-right .line {
  left: auto;
  right: 1.42857rem;
}

.v-timeline.align-right .timeline-box .box-label {
  justify-content: flex-end;
}

.v-timeline.align-right .timeline-box .box-items .item {
  flex-direction: row-reverse;
  text-align: right;
}

.v-timeline.align-right .timeline-box .box-items .item .content-block .item-header {
  justify-content: flex-end;
}

.v-timeline.align-right .timeline-box .box-items .item .content-block .item-desc {
  text-align: right;
}

.v-timeline.align-right.dots .line {
  left: auto;
  right: 0.35714rem;
}

.v-timeline.align-center .line {
  display: none;
}

.v-timeline.align-center .timeline-box .box-label {
  justify-content: center;
}

.v-timeline.align-center .timeline-box .box-items .item {
  flex-direction: column;
  margin: 0.71429rem -0.35714rem;
}

.v-timeline.align-center .timeline-box .box-items .item .icon-block {
  margin-bottom: 0.71429rem;
}

.v-timeline.align-center .timeline-box .box-items .item .content-block {
  padding-bottom: 2.85714rem;
  position: relative;
  text-align: center;
}

.v-timeline.align-center .timeline-box .box-items .item .content-block:after {
  background: #e4e4e4;
  bottom: 0;
  content: "";
  height: 2.14286rem;
  left: 50%;
  margin-left: -1px;
  position: absolute;
  width: 2px;
}

.v-timeline.align-center .timeline-box .box-items .item .content-block .item-header {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.71429rem;
}

.v-timeline.align-center .timeline-box:last-child .box-items .item:last-child .content-block {
  padding-bottom: 0;
}

.v-timeline.align-center .timeline-box:last-child .box-items .item:last-child .content-block:after {
  display: none;
}

@media (min-width: 768px) {
  .v-timeline.align-between .line {
    left: 50%;
  }

  .v-timeline.align-between .timeline-box .box-label {
    justify-content: center;
  }

  .v-timeline.align-between .timeline-box .box-items .item {
    margin-left: 0;
    margin-right: 0;
    width: 50%;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) {
    flex-direction: row-reverse;
    text-align: right;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) .icon-block {
    margin-right: -1.42857rem;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(odd) .content-block {
    margin-left: 0;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) {
    align-self: flex-end;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) .icon-block {
    margin-left: -1.42857rem;
  }

  .v-timeline.align-between .timeline-box .box-items .item:nth-child(2n) .content-block {
    margin-right: 0;
  }

  .v-timeline.align-between.dots .timeline-box .box-items .item:nth-child(odd) .icon-block {
    margin-right: -0.35714rem;
  }

  .v-timeline.align-between.dots .timeline-box .box-items .item:nth-child(2n) .icon-block {
    margin-left: -0.35714rem;
  }
}

.icon {
  border-radius: 6px;
  display: inline-flex;
  font-size: 1.43rem;
  padding: 0.35714rem;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    color 0.2s ease-in-out;
  vertical-align: top;
}

.page-sign-in {
  background:  50% 50% no-repeat;
}

.page-sign-up {
  background:  50% 50% no-repeat;
}

.page-404 {
  background:  50% 50% no-repeat;
}

.page-404 .content-box {
  background: hsla(0, 0%, 100%, 0.9) !important;
}

.page-404 .content-box .content-body .icon {
  color: rgba(237, 85, 100, 0.5);
  font-size: inherit;
  padding: 0;
}

.page-500 {
  background:  50% 50% no-repeat;
}

.page-500 .content-box {
  background: hsla(0, 0%, 100%, 0.9) !important;
}

.page-500 .content-box .content-body .icon {
  color: rgba(237, 85, 100, 0.5);
  font-size: inherit;
  padding: 0;
}

.accent-palette {
  color: #336cfb;
}

.text-danger {
  color: #ed5564 !important;
}

.github-color {
  color: #24292e;
}

.twitter-color {
  color: #1da1f2;
}

.linkedin-color {
  color: #0073b1;
}

.youtube-color {
  color: red;
}

.nowrap {
  white-space: nowrap;
}

.hidden {
  display: none !important;
}

.no-style {
  background: none;
  border: 0;
  outline: none;
  padding: 0;
}

.elements-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin: -0.35714rem;
}

.elements-list>* {
  margin: 0.35714rem;
}

.chat-container {
  display: flex;
  height: 200px !important;
  position: relative;
  overflow: hidden;
}

.chat-container.container-h-100 {
  height: 100px !important;
}

.chat-container.container-h-50 {
  height: 50px !important;
}

.chat-container.container-h-300 {
  height: 300px !important;
}

@media (max-width: 767px) {
  .chat-container.container-h-300 {
    height: 200px !important;
  }
}

.chat-container.container-h-400 {
  height: 400px !important;
}

@media (max-width: 767px) {
  .chat-container.container-h-400 {
    height: 300px !important;
  }

  .contain-aboutus {
    padding: 0px !important;
  }

  .form-login-left {
    padding: 50px !important;
    width: 100% !important;
  }
}

.chat-container.negative-indents {
  margin-top: -1.42857rem;
  margin-bottom: -1.42857rem;
}

.rotate {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.animated.delay-01s {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.animated.delay-02s {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.animated.delay-03s {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.animated.delay-04s {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.animated.delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.animated.delay-06s {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.animated.delay-07s {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.animated.delay-08s {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.animated.delay-09s {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

.rounded-20 {
  border-radius: 20px !important;
}

.rounded-500 {
  border-radius: 500px !important;
}

.btn-close {
  background: none;
  box-shadow: none !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  opacity: 1;
  outline: none !important;
  text-shadow: none;
  transition: color 0.2s ease-in-out;
}

.btn-close:hover {
  color: #ed5564;
}

.map {
  height: 100%;
}

.icons-list {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.icons-list .icon {
  font-size: 30px;
  margin: 0.5rem;
}

.add-action-box {
  bottom: 5.71429rem;
  position: fixed;
  right: 1.42857rem;
  z-index: 9997;
}

.add-action-box .btn {
  font-size: 30px;
  min-width: 5rem !important;
}

.department .team {
  flex-wrap: wrap;
}

.department .team .team-img {
  box-shadow: 0 18px 24px rgba(0, 0, 0, 0.12);
  margin-right: -10px;
}

.bg-gradient {
  background: linear-gradient(90deg, #fff, #ebebeb) !important;
}

.address-col {
  min-width: 150px;
}

.fs-20 {
  font-size: 20px;
}

.fs-30 {
  font-size: 30px;
}

.fs-48 {
  font-size: 48px;
}

.personal-info-card .card-body {
  padding-top: 0 !important;
}

.personal-info-card .user-actions {
  margin-top: -50px;
}

.opacity-50 {
  opacity: 0.5;
}

/* login */
.form-login {
  padding: 45px 45px 45px 45px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  color: white;
}

.form-login-left {
  padding: 130px 39px 82px 30px;
  border-radius: 10px;
  background: rgba(8, 62, 96, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  height: 510px;
  position: relative;
}

.about-us-button {
  background: linear-gradient(270deg, #165e8d 0%, #083e60 100%) !important;
  margin-top: 100px;
}

.contain-aboutus-login {
  padding: 115px 66px 66px 66px;
}

.input-filed {
  height: 80px;
  margin-top: 10px;
}

/* company */
.card-breadcrumb {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 10px 20px;
}

.card-body-company {
  margin-top: 13px;
}

.card-company {
  border-radius: 10px;
  border: 1px solid #083e60;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  margin-top: 5%;
  height: 360px;
}

.card-body-company>h6,
.card-body-company>p {
  height: 25px;
}

.button-details {
  border-radius: 5px;
  background: #fff;
  color: #083e60;
  padding: 4px 8px 4px 4px;
  border-color: white;
  position: relative;
  top: 13px;
}

/* popup */

.title-popup {
  color: #083e60;
  margin-top: 0px;
}

.title-filed {
  color: #353a3d;
  font-weight: 700;
  font-size: 12px;
}

.modal-footer {
  border-top: none !important;
}

.add-logo {
  border-radius: 10px;
  background: white;
  gap: 10px;
  color: #a6a6a6;
  width: 100px;
  height: 85px;
  text-align: center;
  font-size: 53px;
}

/* .modal-content {
  background: #f3f5f9;
} */


.contain-pop {
  padding-right: 10%;
  padding-left: 10%;
  margin-top: 5%;
}

.button-create {
  width: 180px;
}

.card-gender {
  padding: 10;
  padding: 10px;
  text-align: center;
  border: white;
  border-radius: 5px;
  color: #083e60;
  font-weight: 400;
  font-size: 12px;
  background: white;
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.close {
  color: #083e60;
}

.modal-body {
  overflow-y: auto !important;
}

.dropdown-of-add-company {
  border: 1px solid #ebebeb;
  background-color: #ebebeb;
  border-radius: 20px;
  display: block;
  font-size: 1rem;
  height: auto;
  line-height: 1.43;
  outline: none;
  padding: calc(0.71429rem - 1px) 1.42857rem;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
  will-change: background, border, box-shadow, color;
  width: 100%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 50%;
}

/* about us */
.title-aboutus {
  background: #083e60;
  box-shadow: 0px 0px 100px 100px #083e60;
  color: white;
}

.contain-aboutus {
  padding: 0px 250px 40px 250px;
}

.founders {
  position: relative;
  text-align: center;
}

.name-aboutus {
  color: #083e60;
  padding: 4px;
  font-weight: 700;
}

.section-3 {
  position: relative;
  text-align: center;
}

.contain-right {
  padding: 40px 0px 6px 219px;
}

.contain-aboutus-first {
  padding: 40px 30px 40px 270px;
}

@media (max-width: 576px) {
  .contain-aboutus-first {
    padding: 18px 0px 0px 82px;
  }

  .contain-right {
    display: none;
  }

  .contain-aboutus {
    padding: 0px;
  }

  .card-1-line {
    width: 49px !important;
    font-size: 9px !important;
  }

  .card-2-line {
    width: 49px !important;
    font-size: 9px !important;
  }
}

/* company detailes */
.card-company-detailes {
  border-radius: 10px;
  border: 1px solid #083e60;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
}

.card-body-company {
  border-radius: 10px;
  background: #083e60;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  color: white !important;
}

.details-date {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: black;
  margin-top: 10%;
}

.info-card {
  border-radius: 5px;
  background: #0f4f78;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contain-company-det {
  padding: 1% 5% 5% 5%;
}



.contain-two {
  padding-left: 15px;
  padding-right: 15px;
}

.title-project {
  color: #083e60;
  font-size: 16px;
  font-weight: 500;
}

.list-head {
  width: 55px !important;
  margin-left: 0.7%;
}

.icon-list {
  position: absolute;
  top: 20%;
  left: 6%;
}

.label-list {
  position: absolute;
  top: 8px;
  left: 30px;
}

.contain-head {
  border-bottom: 1px solid var(--main-color, #083e60);
  padding: 20px 24px 19px 24px;
}

.title-two {
  color: #083e60;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
}

.title-three {
  color: white;
}

.contain-three {
  border-bottom: 1px solid var(--gray-200, #eaecf0);
  background: #083e60;
  height: 56px;
  padding: 8px 24px;
}

.badge-progress-warning {
  border-radius: 16px;
  background: #fef9c3;
  padding: 6px 12px 8px 12px !important;
  color: #713f12;
  border-color: transparent;
}

.badge-progress-success {
  border-radius: 16px;
  background: #ecfdf3;
  padding: 6px 12px 8px 12px !important;
  color: #027a48;
  border-color: transparent;
}

.badge-progress-danger {
  border-radius: 16px;
  background: #fee2e2;
  padding: 6px 12px 8px 12px !important;
  color: #7f1d1d;
  border-color: transparent;
}

.badge-progress-watting {
  border-radius: 16px;
  background: #e1caff;
  padding: 6px 12px 8px 12px !important;
  color: #472376;
  border-color: transparent;
}

.badge-progress-Paused {
  border-radius: 16px;
  background: #777777;
  padding: 6px 12px 8px 12px !important;
  color: #454545;
  border-color: transparent;
}

.dot-prog {
  color: #facc15;
  font-size: 10px !important;
}

.fa-circle {
  font-size: 10px !important;
}

thead .tr-head th {
  height: 26px;
  border-bottom: 1px solid var(--main-background, #f3f5f9);
  background: var(--d-9-d-9-d-9, #9ec2d9);
  color: white;
  font-size: 13px;
  text-align: center;
}

.tr-head-two td {
  background-color: #083e60 !important;
  text-align: center;
}

.tr-head-first th {
  background-color: white !important;
  text-align: center;
}

[type="file"]::-webkit-file-upload-button {
  display: none;
}

[type="file"]:before {
  content: "\f093";
  font-family: FontAwesome;
  color: #083e60;
}

.file-custom {
  width: 100%;
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #fff;
  background: white;
}

.btn-group,
.btn-group-vertical {
  width: 100%;
  margin: 8px 0;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-block;
  border: 1px solid #fff;
  background: white;
}





.btn.btn-link::before {
  content: "\f107";
  font-family: FontAwesome;
}

.btn.btn-link.collapsed::before {
  content: "\f105";

  font-family: FontAwesome;
}

.btn.btn-link {
  margin-top: -10px;
  color: white;
  float: left;
  /* margin-left: -30px; */
}

.table-striped tbody tr {
  text-align: center;
}

.btn-action {
  background-color: white;
  border-color: white;
  color: #083e60;
  border-radius: 5px;
}



/* .contain-details-episode {
  padding-left: 65px;
  padding-right: 65px;
}

.btn-det-esiponde {
  width: 250px;
  margin-top: 90px;
} */

.title-stepper {
  margin: 0px;
  display: flex;
  list-style: none;
  justify-content: space-between;
  margin: 55px;
  margin-top: -50px;
  padding: 0;
}

ol.stepper {
  --default-b: lightgrey;
  --default-c: #083e60;
  --active-b: #78e176;
  --active-c: white;
  --circle: 3.5em;
  /* size of circle */
  --b: 5px;
  /* line thickness */
  display: flex;
  list-style: none;
  justify-content: space-between;
  background: linear-gradient(var(--default-c) 0 0) no-repeat 50% calc((var(--circle) - var(--b)) / 2) / 100% var(--b);
  /* counter-reset: step; */
  margin: 50px;
  padding: 0;
  font-size: 22px;
  font-weight: bold;
  /* counter-reset: step; */
}

.start {
  display: grid;
  place-content: center;
  height: 60px;
  width: 60px;
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--active-b);
  color: var(--active-c);
  border-radius: 50%;
  z-index: 1;
  margin-top: 10px;
}

.transcribe {
  display: grid;
  place-content: center;
  height: 60px;
  width: 60px;
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--default-c);
  color: var(--active-c);
  border-radius: 50%;
  z-index: 1;
  margin-top: 10px;
}

.adaptation,
.done,
.translation {
  display: grid;
  place-content: center;
  height: 60px;
  width: 60px;
  border: 5px solid #fff;
  box-sizing: border-box;
  background: var(--default-b);
  color: var(--active-c);
  border-radius: 50%;
  z-index: 1;
  margin-top: 10px;
}

.start>img,
.transcribe>img,
.adaptation>img,
.done>img,
.translation>img {
  width: 30px;
}

.btn-det-esiponde-update {
  background-color: #6d6f70 !important;
  width: 250px;
  margin-top: 90px;
  border-color: #6d6f70 !important;
}

.newtons-cradle {
  --uib-size: 70px;
  --uib-speed: 1.2s;
  --uib-color: #083e60;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--uib-size);
  height: var(--uib-size);
  margin: 0px auto;
  text-align: center;
}

.newtons-cradle__dot {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  width: 25%;
  transform-origin: center top;
}

.newtons-cradle__dot::after {
  content: "";
  display: block;
  width: 100%;
  height: 25%;
  border-radius: 50%;
  background-color: var(--uib-color);
}

.newtons-cradle__dot:first-child {
  animation: swing var(--uib-speed) linear infinite;
}

.newtons-cradle__dot:last-child {
  animation: swing2 var(--uib-speed) linear infinite;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  25% {
    transform: rotate(70deg);
    animation-timing-function: ease-in;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(0deg);
    animation-timing-function: linear;
  }

  50% {
    transform: rotate(0deg);
    animation-timing-function: ease-out;
  }

  75% {
    transform: rotate(-70deg);
    animation-timing-function: ease-in;
  }
}

.contain-load {
  margin: 0px auto;
  text-align: center;
  position: relative;
  top: 200px;
}

.timer {
  font-size: 18px;
  font-weight: bold;
}

/* new */
.dropdown-user {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 14px;
  z-index: 1;
  right: 2px;
}

.icofont-logout {
  font-size: 15px !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content>a {
  color: #083e60;
}

.btn-cycle-1 {
  background-color: #78e176 !important;
  border-color: #78e176 !important;
  margin-top: 15px;

}

.btn-cycle-2 {
  background-color: #efc83c !important;
  border-color: #efc83c !important;
  margin-top: 15px;
 
}

.btn-cycle-3 {
  background-color: #d44545 !important;
  border-color: #d44545 !important;
  margin-top: 15px;

}

.contain-cycle {
  padding-left: 85px;
  padding-right: 85px;
}

.episode-list {
  border: 1px solid;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
}

.icon-close-line {
  color: #f67d7d;
  font-size: 20px;
}

.line-episode-card {
  background-color: white;
  width: 100%;
  font-weight: bold;
  padding: 7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.line-episode-card-2 {
  background-color: white;
  width: 100%;
  font-weight: bold;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: -13px;
  border-radius: 0px;
}

.line-episode-card-3 {
  background-color: white;
  width: 100%;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: -13px;
  border-radius: 5px !important;
}

.line-episode-card-4 {
  background-color: white;
  width: 100%;
  font-weight: bold;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
}

.head-line-w1 {
  color: #083e60;
  font-weight: bold;
}

.head-line-w2 {
  color: #083e60;
}

.head-line-w3 {
  color: #f67d7d;
}

.icon-line {
  color: #083e60;
  font-size: 18px !important;
}

.head-line-3 {
  background-color: #083e60;
  color: white;
  border-radius: 5px 5px 0px 0px;
}

.body-line {
  color: #083e60;
}

.badge-progress-warning-lines {
  border-radius: 16px;
  background: #fef9c3;
  color: #713f12;
  border-color: transparent;
  height: 24px;
  width: 92px;
  font-size: 11px !important;
  padding: 10px !important;
}

.Character-select {
  border: none;
  color: white;
}

.Character-select>option {
  color: #000;
}

.table-line-header {
  width: calc(100% - 15px);
  margin-left: 5px;
}



.contain-character {
  overflow-y: scroll;
  margin-top: 3px;
  overflow-x: hidden;
  height: 100px;
}

.contain-character::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.contain-character::-webkit-scrollbar {
  width: 7px;
}

.contain-character::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #083e60;
}

/*For WebKit-based browsers like Chrome and Safari */
.ReactVirtualized__List::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.ReactVirtualized__List::-webkit-scrollbar {
  width: 7px;
}

.ReactVirtualized__List::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #083e60;
}

/* For Firefox */
.ReactVirtualized__List {
  scrollbar-color: #083e60 #f5f5f5;
  scrollbar-width: 7px;
}

/* Add vertical margin only on small screens */
@media (max-width: 767px) {
  .row>[class*="col-"] {
    margin-bottom: 10px;
    /* Adjust the margin as needed */
  }

  /* Optional: Remove the margin on medium and larger screens */
  @media (min-width: 768px) {
    .row>[class*="col-"] {
      margin-bottom: 0;
    }
  }
}


.gap-6 {
  gap: 2rem;
}

.gap-8 {
  gap: 2.5rem;
}

.px-5 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.px-6 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;

}

.py-5 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.py-6 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;

}

.p-5 {
  padding: 1.75rem !important;
}

.p-6 {
  padding: 2rem !important;
}



.mx-5 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.mx-6 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-5 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.my-6 {
  margin: 2rem !important;
  margin-bottom: 2rem !important;
}

.m-5 {
  margin: 1.75rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
 
}