.container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: transform 0.2s ease-in-out;
  width: 210px;
  will-change: transform, width;
  -webkit-overflow-scrolling: touch;
  z-index: 9997;
}
.container.vertical {
  transform: translateX(-17.14286rem);
}

.user-area {
  display: flex;
  flex-direction: column;
}
.logo-wrap {
  align-items: center;
  color: inherit;
  display: flex;
  height: 100%;
  padding: 0 2.85714rem;
  text-decoration: none;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #888;
}
